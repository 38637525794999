// extracted by mini-css-extract-plugin
export var aArrow1 = "sb";
export var aArrow2 = "tb";
export var aButton1 = "ib";
export var aButton2 = "jb";
export var aButton3 = "kb";
export var aButton4 = "lb";
export var aButton5 = "mb";
export var aButtonHeaderLogin = "nb";
export var aButtonHeaderRegister = "ob";
export var aButtonHeaderSpLogin = "rb";
export var aButtonLogin = "qb";
export var aButtonRegister = "pb";
export var aDate = "hb";
export var aEmpty = "db";
export var aInner = "gb";
export var aText = "ub";
export var aTitle = "eb";
export var animationMarquee = "vb";
export var pNewsList = "fb";